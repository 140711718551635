@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
/* font-family: 'Noto Sans', sans-serif; */
/* font-family: 'IBM Plex Mono', monospace; */

@font-face {
    font-family: FireCode;
    src: url(./fonts/FiraCode-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
